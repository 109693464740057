
import { mapState, mapActions, mapGetters } from 'vuex';
import { kpiList } from '../../../util/mockData/kpiMapping';
import { colors } from 'quasar';
import { addKpiCustomization } from '@/util/kpis';
import { APICreatePreferences, APIGetSavedPreferences } from '@/api/kpi';

export default {
  data() {
    return {
      selectedIndex: [],
      kpiFormatted: {},
      selectOptionsKpi: [],
      loading: false,
      series: [],
      kpisList: [],
      customTitles: [
        this.$t('KPI.sls'),
        this.$t('KPI.aip'),
        this.$t('KPI.upt'),
        this.$t('KPI.ap'),
        this.$t('KPI.cn'),
        this.$t('KPI.trn'),
        this.$t('KPI.trf'),
        this.$t('KPI.pb'),
        this.$t('KPI.spt'),
        this.$t('KPI.tpt'),
        this.$t('KPI.cr'),
        this.$t('KPI.dw'),
        this.$t('KPI.er'),
        this.$t('KPI.br'),
        // this.$t('KPI.facadestops'),
        this.$t('KPI.sl'),
        this.$t('KPI.sh'),
        this.$t('KPI.cs'),
        this.$t('KPI.se'),
        this.$t('KPI.rov'),
        this.$t('KPI.maxoccupancy'),
        this.$t('KPI.minoccupancy'),
        this.$t('KPI.avgoccupancy'),
        this.$t('KPI.avgoccupancy%'),
        this.$t('KPI.numberofalerts'),
        this.$t('KPI.numberofrequests'),
        this.$t('KPI.requesttakenperalert'),
        this.$t('KPI.timebeforetakingrequest'),
        this.$t('KPI.trafficinline'),
        this.$t('KPI.avgusersinline'),
        this.$t('KPI.avgwaitingtime')
      ],
      customSort: [...kpiList],
      chartOptions: {
        chart: {
          type: 'radar',
          height: 540,
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          },
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        colors: ['#3989f5', '#aac7fa'],
        stroke: {
          width: 2,
          dashArray: [0, 5]
          // curve: 'straight'
        },
        legend: {
          position: 'top',
          offsetY: 20,
          markers: { radius: 0, width: 16, height: 4 }
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const selectData = series[0][dataPointIndex]; // Data from the first series
            const compareData = series[1][dataPointIndex]; // Data from the second series

            return `
      <q-card class="tooltip-container flex column q-pa-md">
        <label>${w.globals.labels[dataPointIndex]}</label>
        <div class="tooltip-title flex items-center">
          <div class="tooltip-title-color" style="background:${
            w.globals.colors[0]
          }"></div>
          <label>${w.globals.seriesNames[0]} :</label>
          <label class="q-ml-xs text-bold">${this.getRadarValue(
            selectData,
            0,
            dataPointIndex
          )}</label>
        </div>
        <div class="tooltip-title flex items-center">
          <div class="tooltip-title-color" style="background:${
            w.globals.colors[1]
          }"></div>
          <label>${w.globals.seriesNames[1]} :</label>
          <label class="q-ml-xs text-bold">${this.getRadarValue(
            compareData,
            1,
            dataPointIndex
          )}</label>
        </div>
      </q-card>
    `;
          }
        },

        fill: {
          opacity: 0.1
        },
        markers: {
          size: 4,
          colors: ['transparent'],
          strokeColors: 'transparent'
          // strokeWidth: 2,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '14px'
            }
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            formatter: val => {
              return val + '%';
            }
          }
        },
        responsive: [
          {
            breakpoint: 768, 
            options: {
              chart: {
                height: 460 
              }
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]),
    ...mapState('overview', ['byPeriod']),
    ...mapState('distribution', ['selectedKPIOptions']),
    ...mapState('overview', { kpis: 'kpis' }),
    ...mapState('filter', ['radarChartKpiOptions']),
    ...mapState('user', ['kpiPreferences'])
  },
  watch: {
    selectOptionsKpi: {
      handler: 'loadData',
      immediate: true
    },
    selectedKPIOptions() {
      this.selectOptionsKpi = this.selectedKPIOptions;
    },
    kpis(newValue) {
      this.kpisList = addKpiCustomization(newValue);
      // this.kpisList = Object.values(newValue)
      //   .map((v, i) => ({
      //     ...v,
      //     kpikey: Object.keys(newValue)[i]
      //   }))
      //   .sort(this.kpisorter);
    }
  },
  async created() {
    // this.kpisList = this.kpis;
    this.kpisList = addKpiCustomization(this.kpis);

    var selectKpiList = localStorage.getItem('selectedDetailKPIF')?.split(','); //saved kpis

    if (selectKpiList != null && selectKpiList != '') {
      this.selectOptionsKpi = selectKpiList;
    } else {
      // this.selectOptionsKpi = this.selectedKPIOptions; //this.selectedKPIOptions;
      this.selectOptionsKpi = ['sls', 'cn', 'trf', 'trn', 'ap', 'aip', 'upt']; //this.selectedKPIOptions;
      // this.customTitles = this.customSort.map(kpi => this.$t(`KPI.${kpi}`));
    }
    if (
      !this.selectOptionsKpi ||
      this.selectOptionsKpi == '' ||
      this.selectOptionsKpi.length <= 3
    ) {
      for (var p = 0; p < this.kpiPreferences.length; p++) {
        if (this.kpiPreferences[p].metricKey)
          this.selectOptionsKpi.push(this.kpiPreferences[p].metricKey);
      }
    }
    this.selectOptionsKpi = [...new Set(this.selectOptionsKpi)];

    this.selectOptionsKpi = this.selectOptionsKpi.filter(
      item => item !== 'undefined'
    );
    this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('RadarChart.radar')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        png: {
          filename: `${this.$t('RadarChart.radar')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        csv: {
          filename: `${this.$t('RadarChart.radar')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        }
      }
    };
  },
  methods: {
    ...mapActions('distribution', ['fetchDistributionByPeriod']),
    ...mapActions('overview', ['fetchDetailOverview']),
    async getSaveKPIs() {
      var urlPage = window.location.pathname;
      if (urlPage != null && urlPage != '') {
        var res = await APIGetSavedPreferences(urlPage + '/kpi'); // add /kpi
        return res;
      }
      return [];
      //do stuff
    },
    toggleValue(i) {
      if (this.selectedIndex.includes(i)) {
        this.selectedIndex = this.selectedIndex.filter(item => item != i);
      } else {
        this.selectedIndex.push(i);
      }
    },
    kpisorter(x, y) {
      return (
        this.customSort.indexOf(x.kpikey.trim().toLowerCase()) -
        this.customSort.indexOf(y.kpikey.trim().toLowerCase())
      );
    },
    radarSorter(x, y) {
      return (
        this.selectOptionsKpi.indexOf(x.metricID) -
        this.selectOptionsKpi.indexOf(y.metricID)
      );
    },
    getRadarValue(value, seriesIndex, dataPointIndex) {
      switch (seriesIndex) {
        case 1:
          return (
            this.byPeriod?.comparedKPIValues[dataPointIndex]?.formattedValue ||
            0
          );
        case 0:
          return (
            this.byPeriod?.selectedKPIValues[dataPointIndex]?.formattedValue ||
            0
          );
        default:
          return value;
      }
    },
    async loadData(newValue, oldValue) {
      try {
        if (newValue.length) {
          this.loading = true;
          const filterParams = {
            startDate: this.getStartingPeriod,
            endDate: this.getEndingPeriod,
            compareStartDate: this.getStartingCompared,
            compareEndDate: this.getEndingCompared,
            storeCodes: this.getStoreCodesList,
            daysOfWeek: this.getDaysOfWeekList,
            kpis: newValue.join(',')
          };
          await Promise.all([
            this.fetchDetailOverview(filterParams)
            // this.fetchDistributionByPeriod(filterParams)
          ]);
          // this.chartOptions = {
          //   ...this.chartOptions,
          //   xaxis: {
          //     categories: [
          //       ...this.byPeriod.selectedKPIValues
          //         .sort(this.radarSorter)
          //         .map(
          //           kpi =>
          //             this.kpiPreferences.find(
          //               k => k.metricKey === kpi.metricID
          //             ).title
          //         )
          //     ]
          //   }
          // };
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              categories: [
                ...this.byPeriod.selectedKPIValues
                  .sort(this.radarSorter)
                  .map(kpi => {
                    const kpiPreference = this.kpiPreferences.find(
                      k => k.metricKey === kpi.metricID
                    );
                    return kpiPreference ? kpiPreference.title : kpi.metricID; // Use empty string if no match
                  })
              ]
            }
          };

          this.series = [];

          const selectedKPIValues =
            this.byPeriod?.selectedKPIValues?.sort(this.radarSorter) || [];

          const comparedKPIValues =
            this.byPeriod?.comparedKPIValues?.sort(this.radarSorter) || [];
          //
          const selected = selectedKPIValues.map((kpi, index) => {
            const val =
              kpi.metricID != 'dw'
                ? (kpi.value / comparedKPIValues[index].value) * 100
                : kpi.variationP;
            return isNaN(val) || !isFinite(val) ? 0 : val;
          });

          const compared = comparedKPIValues.map((kpi, index) => {
            const val = (kpi.value / comparedKPIValues[index].value) * 100;
            return isNaN(val) || !isFinite(val) ? 0 : val;
          });

          this.series = [
            {
              name: this.$t('KpiAnalysis.selected_period'),
              data: [...selected]
            },
            {
              name: this.$t('KpiAnalysis.compared_period'),
              data: [...compared]
            }
          ];
        }
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `${error}`
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
